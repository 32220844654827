//import { useNavigate } from "react-router-dom";
import "../styles/cards.css";
import { Link } from "react-router-dom";

const Card = ({ imageSource, title, description, url }) => {
   // let navigate = useNavigate();
  
  return (
    <Link className="card-link" to={`/${url}`} >
    
    <div
      className='card shadow text-center  bg-light'
      style={{ width: "15rem", height: "auto" }}
    >
      <img src={imageSource} alt='' />
      <div className='card-body'>
        <h3 className='card-title'>{title}</h3>
        <p className='card-description'>{description}</p>
      </div>
    </div>
    </Link>
  );
};

export default Card;