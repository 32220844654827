import "../styles/hardinessMap.css";
import React, { useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import LRImage from "../assets/images/LowResPlantHardiness.jpg";


const HardinessMap = () => {
  const [showModal, setShowModal] = useState(false);
  const [HRImageSrc, setHRImageSrc] = useState("");

  useEffect(() => {
    if (showModal) {
      const loadImage = async () => {
        const highResImage = await import(
          "../assets/images/HighResPlantHardiness.jpg"
        );
        setHRImageSrc(highResImage.default);
      };

      loadImage();
    }
  }, [showModal]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHRImageSrc("");
  };

  return (
    <div className="container">
      <div className="thumbnail pt-3">
        <img
          className="map-thumbnail rounded"
          src={LRImage}
          alt="low resolution map"
          onClick={handleOpenModal}
          />
          <p className="pb-2">as click en el mapa para alta resolución</p>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-fullscreen" centered>
        <Modal.Header closeButton>
          <Modal.Title>Mapa de alta resolución</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {HRImageSrc && (
            <img
              src={HRImageSrc}
              loading="lazy"
              alt="high resolution map"
              className="img-fluid"
            />
          )}
        </Modal.Body>
        
      </Modal>
    </div>
  );
};

export default HardinessMap;

