import { Formik, Form } from "formik";
import { TextField, TextArea } from "../components/TextField";
import * as Yup from "yup";
import { PersistFormikValues } from "formik-persist-values";
import Swal from "sweetalert2";

const Contact = () => {
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const validate = Yup.object({
    name: Yup.string()
      .trim()
      .min(2, "Minimo 2 caracteres")
      .max(30, "Maximo 30 caracteres")
      .required("Nombre Requerido"),
    email: Yup.string().email("E-mail invalido").required("E-mail es requerido"),
    comment: Yup.string()
      .trim()
      .min(10, "Explique un poco mas siga escribiendo")
      .max(500, "Menos de 500 caracteres por favor")
      .required("Requerido"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        comment: "",
      }}
      validationSchema={validate}
      onSubmit={(values, actions) => {
        fetch("/contact", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact-form", ...values })
        })
        .then(response => {
        if (response.ok) {
          console.log(response);
          Swal.fire({
            title: "Success",
            text: "Gracias, Su comentario ah sido recibido",
            icon: "success",
            confirmButtonText: "ok",
            confirmButtonColor: "#0b7276"
          });
        }
        else if (!response.ok){
          Swal.fire({
            title: "Error!",
            text: "Hubo un error en nuestro servidor mensaje no recibido",
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#0b7276"
          });
        }
      })
      .catch(error => {
        
        console.log(error);
      });
      actions.resetForm({

        name: "",
        email: "",
        comment: "",
      })
  }}
    >
      {formik => (
        <div className='container col-md-6' id="contact">
          <h1 className='font-weight-bold-display-4 text-center'>¿Gustas contactarnos?</h1>
          <h3 className="text-center">Si tienes una sugerencia o comentario por favor mandanos un mensaje.</h3>
          <Form className="contact-form-div">
          <input type="hidden" name="contact-form" value="contact"/>
            <TextField 
              label='Nombre'
              name='name'
              type='text'
              placeholder='Nombre'
            />
            <TextField
              label='E-mail'
              name='email'
              type='email'
              placeholder='E-mail@email.com'
            />
            <TextArea
              label='Comentario'
              name='comment'
              type='textarea'
              placeholder='Deja tu comentario'
            />
            <button className='btn btn-success m-1' type='submit'>
              Enviar
            </button>
            <button className='btn btn-danger m-2' type='reset'>
              Borrar
            </button>
            <PersistFormikValues/>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Contact;

/*
USE WITH FORMIK AND NODE.js TOO SLOW ON RENDER BACKEND.
import { Formik, Form } from "formik";
import { TextField, TextArea } from "../components/TextField";
import * as Yup from "yup";
import { PersistFormikValues } from "formik-persist-values";
import Swal from "sweetalert2";
import axios from "axios";

const ContactForm = () => {
  const validate = Yup.object({
    name: Yup.string()
      .trim()
      .min(2, "Minimo 2 caracteres")
      .max(30, "Maximo 30 caracteres")
      .required("Nombre Requerido"),
    email: Yup.string().email("E-mail invalido").required("E-mail es requerido"),
    comment: Yup.string()
      .trim()
      .min(10, "Explique un poco mas siga escribiendo")
      .max(500, "Menos de 500 caracteres por favor")
      .required("Requerido"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        comment: "",
      }}
      validationSchema={validate}
      onSubmit={(values, actions) => {
        console.log(values);
        axios
          .post("https://muchachitos-gardening.onrender.com/feedback", values)

          .then(response => {
            if (response.status === 201) {
              console.log(response);
              Swal.fire({
                title: "Enviado",
                text: "Gracias, Su comentario ah sido recibido",
                icon: "success",
                confirmButtonText: "Cerrar",
              });
            }
          })
          .catch(error => {
            Swal.fire({
              title: "Error!",
              text: "Hubo un error en nuestro servidor mensaje no recibido",
              icon: "error",
              confirmButtonText: "Cerrar",
            });
            console.log(error);
          });
          actions.resetForm({

            name: "",
            email: "",
            comment: "",
          })
      }}
    >
      {formik => (
        <div className='container col-md-6'>
          <h1 className='font-weight-bold-display-4'>Gustas contactarnos?</h1>
          <Form>
            <TextField
              label='¿Cuál es su nombre?'
              name='name'
              type='text'
              placeholder='Nombre'
            />
            <TextField
              label='¿Su e-mail?'
              name='email'
              type='email'
              placeholder='E-mail@email.com'
            />
            <TextArea
              label='Comentario'
              name='comment'
              type='textarea'
              placeholder='Pregunta o sugerencia'
            />
            <button className='btn btn-success m-1' type='submit'>
              Enviar comentario
            </button>
            <button className='btn btn-danger m-2' type='reset'>
              Borrar formulario
            </button>
            <PersistFormikValues name='contact-form' />
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ContactForm;*/
