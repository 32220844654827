import React from "react";
import { Carousel } from "react-bootstrap";
import "../styles/HomeCarousel.css";
import image1 from "../assets/images/c11.jpg";
import image3 from "../assets/images/c13.jpg";
import image4 from "../assets/images/c14.jpg";
import image5 from "../assets/images/c15.jpg";
import image6 from "../assets/images/c16.jpg";
import image7 from "../assets/images/c17.jpg";
import image8 from "../assets/images/c18.jpg";
import image9 from "../assets/images/c19.jpg";
import image10 from "../assets/images/c20.jpg";
import image11 from "../assets/images/c21.jpg";

function NewCarousel() {
  let allImages = [
    image1,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
  ];

  return (
    <div className="carousel-div">
      <Carousel>
        {allImages.map((image, index) => (
          <Carousel.Item key={index}>
            <img loading="lazy"
              
              decoding="async"
              
              height="auto"
              width="100%"className="d-block w-100" src={image} alt="slider" />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default NewCarousel;


