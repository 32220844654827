import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className='container col-md-4'>
      <h1 className='text-center'>Hola un gusto saludarlos </h1>
      <h3>Un poco sobre nosotros</h3>
      <p>
        Esta pagina fue creada con el propósito de enseñar y aprender todo lo
        que tenga que ver con plantas. Especialmente las comestibles, es un
        rincón el la web que fue echa con mucho amor, para compartir y animar a
        todos a que siembren en sus hogares ya sea en la ciudad, el campo, en
        casa o en apartamento.
      </p>
      <p>
        {" "}
        Tengo sembrando como 12 años en casa comenzó con unas cuantas plantas de
        chile y jitomate ahora siembro de todo , chiles, jitomates, pepinos,
        tomatillos, calabacitas, acelgas, kale, rábanos, cilantro, cebollas etc
        etc.. en un pedazo de tierra relativamente pequeño, de esta manera
        ahorro agua y saco la mayor producción posible. También en estos últimos
        años eh sembrado en contenedores (macetas) eh aprendido bastante y sigo
        aprendiendo cada dia mas. Eh cometido muchos errores a lo largo de estos
        años es por eso que quiero compartir con todos ustedes lo que eh
        aprendido y si hay algo que pueden aportar sus sugerencias o preguntas
        son bienvenidas! <Link to='/contact'>Contáctenos!</Link>
      </p>
    </div>
  );
};

export default About;
