import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import NotFound from "./routes/NotFound";
import NavbarMenu from "./components/Navbar";
import Footer from "./components/Footer"
import SeedGermination from "./dropdownMenu/SeedGermination";
import Soil from "./components/Soil";
import React from "react";

const LazyTomato = React.lazy(() => import("./dropdownMenu/Tomato"));
const LazyTomatillo = React.lazy(() => import("./dropdownMenu/Tomatillo"));
const LazyPeppers = React.lazy(() => import("./dropdownMenu/Peppers"));
const LazyZucchini = React.lazy(() => import("./dropdownMenu/Zucchini"));
const Lazycucumber = React.lazy(() => import("./dropdownMenu/Cucumber"));
const LazyOnion = React.lazy(() => import("./dropdownMenu/Onion"));
const LazyCilantro = React.lazy(() => import("./dropdownMenu/Cilantro"));
const LazyLeafyGreens = React.lazy(() => import("./dropdownMenu/LeafyGreens"));
const LazyCorn = React.lazy(() => import("./dropdownMenu/Corn"));

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <BrowserRouter>
        <NavbarMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<Soil />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          <Route
            path="/tomato"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyTomato />
              </React.Suspense>
            }
          />

          <Route
            path="/peppers"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyPeppers />
              </React.Suspense>
            }
          />

          <Route
            path="/tomatillo"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyTomatillo />
              </React.Suspense>
            }
          />

          <Route
            path="/zucchini"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyZucchini />
              </React.Suspense>
            }
          />

          <Route
            path="/cucumber"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <Lazycucumber />
              </React.Suspense>
            }
          />

          <Route
            path="/onion"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyOnion />
              </React.Suspense>
            }
          />

          <Route
            path="/cilantro"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyCilantro />
              </React.Suspense>
            }
          />

          <Route
            path="/leafygreens"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyLeafyGreens />
              </React.Suspense>
            }
          />

          <Route
            path="/corn"
            element={
              <React.Suspense fallback={<div>Loading........</div>}>
                <LazyCorn />
              </React.Suspense>
            }
          />
          <Route path="/seedGermination" element={<SeedGermination />} />
          <Route path="/soil" element={<Soil />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
