import React from 'react'
import img1 from '../assets/images/SG1.jpg'
import img11 from '../assets/images/SG2.jpg'
import img111 from '../assets/images/SG3.jpg'

const SeedGermination = () => {
  return (
    <div>
        <div className="container bg-light mt-3">
                    <div className="clearfix">
                        <img src={img11} alt="Semillas en semilleros" style={{width: '300px'}} className="col-md-6 rounded float-md-end m-3 ms-md-3"/>
        <h1 className='text-center'>Como germinar semillas</h1>
        <p>
           Para germinar y crecer la semilla se necesita un buen sustrato (tierra) 
        que por lo general consiste de turba (peat moss) con vermiculita (vermiculite) y o perlita
         (perlite) la mayoría de las tiendas grandes ya lo venden echo y ya está esterilizada. 
         Es importante que todo este esterilizado de lo contrario puede haber hongos o enfermedades 
         en los recipientes o tierra que pueden dañar la semilla. Este sustrato es especial que ayuda
          a retener la humedad y a la ves drena el agua muy bien. Y por lo general no contiene ningún
           nutriente este se agrega después que la semilla germine. 
        </p>
        <p>
        Colocar el sustrato húmedo en el recipiente sean semilleros o vasos etc. 
        es más fácil si se humedece antes de poner la semilla. 
        </p>
        <p>
        Colocar la semilla a ¼ de pulgada de profundidad si es semilla chica como la de jitomate o chiles, 
        ½ pulgada si la semilla es grande como la de calabaza, girasol o sandia. 
        </p>
        <p>
        Poner en un lugar a temperatura alrededor de los 70 grados Fahrenheit.
        Mantener húmedo no saturado si se usa mucha agua la semilla no germina.

        </p>
        <p>
        <div>
            <img src={img111} alt="Semillas en semilleros" style={{width: '300px'}} className="col-md-6 rounded float-md-start m-3 ms-md-3"/>
        </div>
        Una vez que las semillas germinaron, darle mucha luz, 
        puede ser en una ventana o si es luz artificial por lo menos 12 horas al día.
        </p>
        <p>
        Cuando las plantas tiren las primeras hojas que en realidad no son hojas y salgan
        las verdaderas se le agrega el fertilizante, el soluble es una buena opción y talvez la mejor,
        solo que se tiene que diluir a un ¼ de lo recomendado, es decir si las indicaciones dicen una 
        cucharada por galón de agua solo usar ¼ por galón. Y regar las plantas con esto dos veces por semana. 
        Ir incrementando el fertilizante a como las plantas lo necesiten. 
        </p>
        <p>
        Ya las plantas están grandes ahora toca sacarlas al sol una o dos horas por día e ir para
         aclimatarlas y no les de un shock, se pueden morir si no se hace esto ya que no están 
        impuestas a clima ni el sol directo. Esto se hace un par de semanas antes del trasplante.
        </p>
        <p>
        Ya pasaron 4 u 8 semanas desde que se comenzaron ahora ya es tiempo de trasplantar. 
        </p>
        <img src={img1} alt="Semillas ya germinaron" style={{width: '300px'}} className="col-md-6 rounded float-md-end m-3 ms-md-3"/>      
        </div>
        </div>
    </div>

  )
}

export default SeedGermination