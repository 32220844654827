import "../styles/footer.css";
import { FaFacebookSquare, FaYoutubeSquare, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = (new Date()).getFullYear();
  return (
    <footer className='footer mt-auto'>
      <div className='footer-container col container-fluid text-center mt-3'>
        <div className='row'>
          <h2>
            <a href='/' className='m-footer'>
              MUCHACHITOS GARDENING
            </a>
          </h2>
          <div className='col'>
            <ul className='list-unstyled p-1'>
              <li>
                <Link className='list-links' to='/'>
                  Inicio
                </Link>
              </li>
              <li>
                <Link className='list-links' to='/about'>
                  Conocenos
                </Link>
              </li>
              <li>
                <Link className='list-links' to='/contact'>
                  Contactanos
                </Link>
              </li>
            </ul>
          </div>
          <div className='col d-flex justify-content-center'>
            <ul className='d-flex  list-unstyled '>
              <li className='pt-3'>
                <a
                  href='https://www.facebook.com/people/Muchachitos-Gardening/100083352038368/'
                  target='_blank' 
                  rel='noreferrer'
                  alt='fb link to muchachitos gardening'
                >
                  <FaTiktok style={{ fill: "black" }} size={50} />
                </a>
              </li>
              <li className='pt-3'>
                <a
                  href='https://www.facebook.com/people/Muchachitos-Gardening/100083352038368/'
                  target='_blank' 
                  rel='noreferrer'
                  alt='fb link to muchachitos gardening'
                >
                  <FaFacebookSquare style={{ fill: "blue" }} size={50} />
                </a>
              </li>
              <li className='pt-3'>
                <a
                  href='https://www.youtube.com/@muchachitosgardeningcultiv7570'
                  target='_blank'
                  rel='noreferrer'
                  alt='Youtube link to muchachitos gardening'
                >
                  <FaYoutubeSquare style={{ fill: "red" }} size={50} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-md text-center'>
          <hr />
          <p className='copyright' id="year">
            Muchachitos Gardening | &copy;
            <span>{currentYear}</span> All
            rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
