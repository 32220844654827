import Cards from '../components/CardsCarousel';
import HardinessMap from '../components/HardinessMap';
import HardinessZone from '../components/HardinessZone';
import HomeCarousel from '../components/HomeCarousel';
import Soil from '../components/Soil';
import "../styles/home.css"

const Home = () => {
  
  return (
    <div>
            
            <div className='container my-3 text-center'>
                <h1 className='welcome text-center p-3'>¡Bienvenidos a Muchachitos Gardening!</h1> 
                <h3 className='m-3'> Aquí aprenderas como cultivar tus propios alimentos.</h3>
            </div>
            <div>
                <HomeCarousel />
            </div>
            <div>
              <Soil/>  
            </div> 
            
              <div className='text-center hardiness container'>
              <HardinessZone />
            

              <HardinessMap />
              </div>

              
            <Cards />
        </div>
    )
}

export default Home;