import Card from "./Card";
import cards from "../data/CardData";
import Carousel from "react-elastic-carousel";
import React, {useEffect} from "react";

const CardsCarousel = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  return (
   
    <div className='container align-items-center'>
      <Carousel breakPoints={breakPoints} className='p-3'>
        {cards.map(card => (
          <div
            className='col-md-4'
            style={{
              height: 650,
              width: 250,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            key={card.id}
          >
            <Card
              title={card.title}
              imageSource={card.image}
              description={card.description}
              url={card.url}
              />
              
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CardsCarousel;