import React from 'react'
import tierra from '../assets/images/PrepararTierra.jpg'

const Soil = () => {
  return (
    <div className='p-3'>
         <div className="container bg-light">
                    <div className="clearfix">
                        <img src={tierra} alt="tierra" style={{width: '15rem'}} className="col-md-6 rounded float-md-end m-3 ms-md-3"/>
                
                        <h3 className='text-center tierra' >Como preparar la tierra.</h3>
                        <p>Buen Suelo para plantar.</p>
                        <p>¿Qué es un buen suelo?</p>
                        <p>  La composición de la tierra es 25% agua, 25% aire(oxigeno),
                            45% minerales y por último 5% materia orgánica. Las plantas requieren buen drenaje y nutrientes para crecer fuertes y producir fruto.  
                            La tierra es diferente en todas partes,  puede tener muchos nutrientes o muy pocos, retener mucha agua o no retenerla o incluso no dejarla pasar. 
                            Por eso, la mejor opción es agregar compost no importa qué tipo de tierra es la que se va sembrar, mezclar una capa de 4 pulgadas con la tierra 
                            existente o si ya se a preparado antes solo con unas 1 o 2 pulgadas cada año puede ser suficiente.
                        </p> 
                        <p>¿Tierra para maceta?</p>
                        <p>Una buena tierra simple para maceta y que se puede hacer en casa es turba (peat moss), perlita (perlite) compost o abono ya descompuesto.</p>
                        <p>¿Como hacer tierra para maceta?</p>
                        <p>    Es muy fácil y sencillo y sale más barato que comprar ya echa.  A menos que solo sea para una maceta talvez es mejor comprar ya echa.
                            Se mezcla 1 parte de turba 1 parte compost ½ parte perlita y se le agrega fertilizante orgánico o uno de liberación lenta. 
                            Es decir, las medidas son: una cubeta de turba, una cubeta de compost, y media cubeta de perlita, más el fertilizante bien mezclado todo. 
                        </p>
                    </div>
                </div> 
    </div>
  )
}

export default Soil