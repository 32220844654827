
import "../styles/navbar.css"; 
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Mlogo from '../assets/images/MgardeningLogoresized.jpg'



const NavbarMenu = () => {
    return (

        <Navbar className="nav-color" expand="lg" >
            <Container>
                <img src={Mlogo} alt="muchachitos logo" style={{ width: '8rem', }}/>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="mx-auto" >
                        <Nav.Link className="navLink" as={Link} to="/">Inicio</Nav.Link>
                        <Nav.Link className="navLink" as={Link} to="/about">Conocenos</Nav.Link>
                        <Nav.Link className="navLink" as={Link} to="/contact">Contacto</Nav.Link>
                            <NavDropdown title="Plantas" id="basic-nav-dropdown">
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/tomato">Tomate/Jitomate</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/peppers">Chiles</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/tomatillo">Tomatillo</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/zucchini">Calabacitas</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/cucumber">Pepino</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/onion">Cebolla</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/cilantro">Cilantro</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/leafygreens">Hojas verdes</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/seedgermination">Germinacion de semillas</NavDropdown.Item>
                            <NavDropdown.Item className="DropDown-text" as={Link} to="/corn">Maiz</NavDropdown.Item>
                            </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}

export default NavbarMenu;