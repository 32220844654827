
import jalapeno from '../assets/images/Jalapeno.jpg'
import cilantroCard from '../assets/images/cilantro/cilantroCard.jpg'
import tomatilloCard from '../assets/images/tomatillo/tomatilloCard.jpg'
import tomatoCard from '../assets/images/tomato/tomatoCard.jpg'



let cards = [
    {
        id:1,
        title: 'Tomates o Jitomates',
        description: 'Mira que fácil es sembrar',
        image: tomatoCard,
        url: "tomato"
    },    
    {
        id:2,
        title: 'Chiles',
        description: 'Mira que facil es sembrar',
        image: jalapeno,
        url: "peppers"
    },    
    {
        id:3,
        title: 'Tomatillo',
        description: 'Mira que facil es sembrar',
        image: tomatilloCard,
        url: "tomatillo"
    },
    {
        id:4,
        title: 'Calabacitas / Zucchini',
        description: 'Mira que facil es sembrar',
        image: jalapeno,
        url: "zucchini"
    },    
    {
        id:5,
        title: 'Pepinos',
        description: 'Mira que facil es sembrar',
        image: jalapeno,
        url: "cucumber"
    },    
    {
        id:6,
        title: 'Cebollas',
        description: 'Mira que facil es sembrar',
        image: jalapeno,
        url: "onion"
    },
    {
        id:7,
        title: 'Cilantro',
        description: 'Mira que facil es sembrar',
        image: cilantroCard,
        url: "cilantro"
    },    
    {
        id:8,
        title: 'Hojas Verdes',
        description: 'Mira que facil es sembrar',
        image: jalapeno,
        url: "leafyGreens"
    },    
    {
        id:9,
        title: 'Maíz',
        description: 'Mira que facil es sembrar',
        image: jalapeno,
        url: "corn"
    }    

]
export default cards; 