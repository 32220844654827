import React, { useState } from 'react';
import axios from 'axios';

const HardinessZone = () => {
  const [zipCode, setZipCode] = useState('');
  const [zoneData, setZoneData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [submittedZipCode, setSubmittedZipCode] = useState('');

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://phzmapi.org/${zipCode}.json`);
      setZoneData(response.data);
      setErrorMessage('');
      setSubmittedZipCode(zipCode);
    } catch (error) {
      console.error(error.response);
      setZoneData(null);
      setErrorMessage('Zip code no encontrado');
      setSubmittedZipCode('');
    }
  };

  const handleButtonClick = () => {
    fetchData();
  };

  return (
    <div className="container mt-3">
      <p className="p-3">
        Para saber qué plantas resisten cierta temperatura y qué plantas puedes tener o cuando plantar o comenzar las semillas es importante saber la zona en que vives. Conocido en inglés como plant hardiness es el método que se usa para determinar qué planta resiste el frío dependiendo de la zona donde vivas. Plant hardiness es el promedio de la temperatura mínima en invierno de tu región. Por ejemplo, una planta de limón crece muy bien en la zona 10a mientras en la zona 4b sería imposible por las bajas temperaturas en invierno. Cuando se compra un árbol en la etiqueta dice que temperaturas son las óptimas para su desarrollo.
      </p>
      <div className="row rounded">
        <div className="col-md-6 offset-md-3">
          <div className="bg-light p-3 rounded">
            <div className="m-3 card-header">
              <h4 className="text-center">Verifica cuál es tu zona</h4>
            </div>
            <div className="">
              <div className="">
                <label htmlFor="zipCode">Escribe tu (zip code) código postal dentro de los Estados Unidos:</label>
                <input
                  type="text"
                  id="zipCode"
                  className="form-control"
                  value={zipCode}
                  onChange={(event) => setZipCode(event.target.value)}
                />
                <button className="btn btn-success mt-3" onClick={handleButtonClick}>
                  Buscar mi zona
                </button>
              </div>
              {errorMessage && <p className="text-danger mt-3">Error: {errorMessage}</p>}
              {zoneData && (
                <div className="mt-3">
                  <h5>Tu zona para el código postal {submittedZipCode} es:</h5>
                  <span>
                    <h3>{zoneData.zone}</h3>
                  </span>
                  {/* Render other relevant data from the API response */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardinessZone;
